import { useState } from 'react';
import { useSession, signIn, SignInOptions } from 'next-auth/react';

interface OneTapSigninOptions {
  parentContainerId?: string;
}

const useOneTapSignin = (
  options?: OneTapSigninOptions &
    Pick<SignInOptions, 'redirect' | 'callbackUrl'>
) => {
  const { parentContainerId } = options || {};
  const [isLoading, setIsLoading] = useState(false);

  // Taking advantage in recent development of useSession hook.
  // If user is unauthenticated, google one tap ui is initialized and rendered
  // const { status } = useSession({
  useSession({
    required: true,
    onUnauthenticated() {
      if (!isLoading) {
        const { google } = window as unknown as any;
        if (google) {
          google?.accounts?.id?.initialize({
            client_id: process.env.NEXT_PUBLIC_ONETAP_GOOGLE_CLIENT_ID!,
            // auto_select: true, // TODO: Uncomment this line if you want to skip the one tap UI
            callback: async (response: any) => {
              setIsLoading(true);
              try {
                // Here we call our Provider with the token provided by google
                const signInResponse = await signIn('googleonetap', {
                  credential: response.credential,
                  redirect: false,
                  ...options,
                });

                if (signInResponse?.ok) {
                  if (signInResponse?.status === 200) {
                    if (signInResponse?.url) {
                      /**
                       * useSession is not updated when calling signIn from next-auth/react
                       * Thats why I am doing a full reload
                       * router.refresh() just refreshes server data not the client data that's why 
                       * router.refresh() is not calling useOneTapSignin again based on condition
                       */
                      // router.refresh();
                      // window.location.reload();
                    }
                  }
                } else {
                  console.error('Login failed:', signInResponse?.error);
                }


              } catch (error) {
                console.error('SignIn Error:', error);
              } finally {
                setIsLoading(false);
              }
            },
            prompt_parent_id: parentContainerId,
          });

          // Here we just console some error situations and reason why the google one tap
          // is not displayed. You may want to handle it depending on yuor application
          google?.accounts?.id?.prompt((notification: any) => {
            if (notification.isNotDisplayed()) {
              console.error(
                'getNotDisplayedReason ::',
                notification.getNotDisplayedReason()
              );
            } else if (notification.isSkippedMoment()) {
              console.error(
                'getSkippedReason  ::',
                notification.getSkippedReason()
              );
            } else if (notification.isDismissedMoment()) {
              console.error(
                'getDismissedReason ::',
                notification.getDismissedReason()
              );
            }
          });
        }
      }
    },
  });

  // if (status === "loading") {
  // }

  return { isLoading };
};

export default useOneTapSignin;
