'use client';
import useOneTapSignin from "@lib/hooks/useOneTapSignin";

const OneTapComponent = () => {
  // const { isLoading: oneTapIsLoading } = useOneTapSignin({
  useOneTapSignin({
    redirect: false,
    parentContainerId: 'oneTap',
  });

  return (
    <div
      id="oneTap"
      style={{ position: 'fixed', top: 0, right: 0, zIndex: 3000 }}
    />
  );
};

export default OneTapComponent;
